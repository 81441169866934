<template>
  <div class="auth-cont password-assistance-cont">
    <div>
      <auth-header></auth-header>
      <div class="auth-body">
        <div class="G-flex">
          <router-link
            class=" mdi mdi-arrow-left P-arrow"
            :to="'/auth/sign-in'"
          >
          </router-link>
          <h5 class="title">Password assistance </h5>
        </div>
        <p class="sub-text">
          Enter the email address associated with your account
        </p>
        <OttInput
          v-model="form.email"
          :error="isValidation || $v.form.email.$error"
          label="Email Address"
        />
        <ErrorMessageContainer
          v-if="errorResend"
          message="The email address you entered is incorrect"
        />
        <OttButtonLoader
          :is-loading="isLoading"
          @onClick="sendEmail"
          button-text="Continue"
          className="primary-lg-btn"
        />
      </div>
    </div>
    <auth-footer></auth-footer>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { email, required } from "vuelidate/lib/validators";
import AuthFooter from "./components/authFooter";
import AuthHeader from "./components/authHeader";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
export default {
  name: "PasswordAssistance",
  components: {
    ErrorMessageContainer,
    OttButtonLoader,
    OttButton,
    OttInput,
    AuthHeader,
    AuthFooter
  },
  validations: {
    form: {
      email: { required, email }
    }
  },
  data() {
    return {
      form: {
        email: ""
      },
      isValidation: false
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.appModule.isLoading,
      errorResend: state => state.forgotPassword.errorResend
    })
  },
  methods: {
    ...mapActions({
      passwordRecovery: "forgotPassword/passwordRecovery"
    }),

    ...mapMutations({
      setLoad: "forgotPassword/setLoad",
      setEmail: "forgotPassword/setEmail",
    }),

    validationFunc() {
      const [fieldName, value] = arguments;
      this.form[fieldName] = value;
      this.$v.form[fieldName].$touch();
    },

    async sendEmail() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        this.setLoad(true);
        await this.passwordRecovery(this.form)
          .then(data => {
            sessionStorage.setItem("email", this.form.email);
            this.setEmail(this.form.email);
            this.$router.push({ path: "/auth/password-send-success" });
          })
          .catch(e => {
            if (e) {
              this.isValidation = true;
            }
          });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/authGeneral";
.password-assistance-cont {
  .title {
    margin-bottom: 30px;
    text-transform: capitalize;
  }
}
.Ott-button-cont {
  width: 100%;
}
.P-arrow {
  position: absolute;
  top: 33px;
  left: 35px;
  font-size: $txt20;
  color: $neutral-color;
  &:hover {
    color: $primary-color;
    cursor: pointer;
    text-decoration: none;
  }
}
</style>
